import React from "react";
import { RiLogoutCircleRLine } from 'react-icons/ri';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import './navbar.css'
import { Redirect } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import MenuItem, { MenuMovil } from "./MenuItem";
import { BsArrowLeftShort } from "react-icons/bs";
import { VscHome } from "react-icons/vsc";

export default class NavBar extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      txtsalir: false,
      txtusuario: "",
      ventana: props.ventana,
      rutaactual: "",
      avanzar: false,
      menu: [
        {
          titulo: "",
          activa: false,
          items: [

            { nombre: "", enlace: "/perfil" },
            { nombre: "Cerrar sesión", enlace: "/" },
          ]
        }
      ]
    }

  }

  componentDidMount() {
    var datos = localStorage.getItem("datos");
    let array = datos.split(",");
    this.setState({ txtusuario: array[1] });




    this.setState(prevState => ({
      menu: prevState.menu.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            titulo: array[1]
          };
        }
        return item;
      })
    }));


  }

  nextPro = () => {
    this.setState({ avanzar: !this.state.avanzar })
  }

  render() {
    return (

      <>



        {(this.state.avanzar == true) ? (
          <Redirect from='/perfil' to={"/home"} />
        ) : null}


        {this.state.txtsalir ?

          <SpinnerModal tipo="full" show={this.state.txtsalir} />

          : null}

        < div className="nabvarcss" >
          {/*< onClick={this.salir} className="lblusuarionav">{this.state.txtusuario} <RiLogoutCircleRLine className="lblusuario-estatus" /></label>*/}



          {this.state.ventana == "perfil" ? (
            <div style={{ position: "absolute", top: "15px", left: "0px" }} onClick={this.nextPro}>
              <div className={""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <BsArrowLeftShort className="hamburgerlbl" onClick={() => this.setState({ openmenu: !this.state.openmenu })} />
                <p className="lbltittledlbl">Inicio</p>
              </div>
            </div>
          )
            : null
          }


          <div className="lblusuarionav">


            <div className="navbar-web-menu">

              <div className="navbar-web-menuitem">
                <MenuItem grupo={this.state.menu[0]} icon={1} ruta={this.state.rutaactual} />
              </div>


            </div>
          </div>


        </div >
      </>
    )

  }
}



