import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import './navbar.css'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FaUserTie } from "react-icons/fa";

import { RiFileList2Fill } from 'react-icons/ri';
import { GoGear } from 'react-icons/go';
import { FaUsersCog } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiFillHome } from 'react-icons/ai';
import { IoMdArrowDropdownCircle } from 'react-icons/io';

const bgImage = require("./../../assets/coin.png");


export default function MenuItem({ direction, ...args }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [seleccionado, setRuta] = useState("");
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [txtusuario, setTxtusuario] = useState("");
    const [txtemail, setTxtemail] = useState("");

    useEffect(() => {

        var datos = localStorage.getItem("datos");
        let array = datos.split(",");
        setTxtusuario(array[1] + " " + array[2]);
        setTxtemail(array[3]);


    });


    function revisar(ruta) {

        if (ruta.nombre == "Cerrar sesión") {
            localStorage.removeItem("ls");

            var loca = window.location.pathname;
            var ara = loca.split('/');
            ara = "/" + ara[1]
            if (ara == "/")
                window.location.reload(true)
        }
        setRuta(ruta.enlace);
    }
    return (
        <div>
            {(seleccionado != "") ? (<Redirect to={seleccionado} />) : null}


            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle tag="span" className="navbar-web-menuitem-button" style={{ color: "black", letterSpacing: "0.6px", fontWeight: "bold" }} caret>

                    {args.grupo.titulo}
                    {(args.icon == 1) ? (<IoMdArrowDropdownCircle className="lblusuario-estatus" style={{ marginLeft: "3px" }} />) : null}




                </DropdownToggle>
                <DropdownMenu {...args} right>
                    {(args.grupo.items.map((el, index) => (
                        <>
                            <DropdownItem className={(args.ruta == el.enlace && args.ruta != "/") ? ("rutaactiva") : null} key={"menuweb" + index}
                                //href={el.enlace}

                                onClick={() => revisar(el)}>

                                {(index == 0) ? (
                                    <>
                                        <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: "10px" }}>

                                            <FaUserTie padding className='profile-image' />

                                            <div className="profile-details" style={{ textAlign: 'center' }}>
                                                <h2 className='lblperfil'>{txtusuario}</h2>
                                                <p className='lblperfilemail'>{txtemail}</p>

                                            </div>
                                        </div>

                                    </>
                                ) :

                                    null}

                                <b style={{ textAlign: "right", width: "100%" }}> {el.nombre}</b>


                            </DropdownItem>
                            {(index == 0) ? (
                                <div className="shr " style={{ background: "#b0b0b0" }}></div>) : null}
                        </>

                    )))}
                </DropdownMenu>
            </Dropdown>

        </div>
    );
}

MenuItem.propTypes = {
    direction: PropTypes.string,
};








export class MenuMovil extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lista: this.props.listamenu,
            nuevaruta: ""
        }
    }

    activaropcion = (opcion, estado) => {
        let listanueva = this.state.lista;

        listanueva.forEach(el => {
            if (el.opcion != opcion)
                el.activa = false
        });
        listanueva[opcion].activa = !estado;
        this.setState({ lista: listanueva });

        if (opcion == 0) {
            this.setState({ nuevaruta: "/" })
        }
    }

    cambiarRuta = (item) => {

        if (item.nombre == "Cerrar sesión") {
            localStorage.removeItem("ls");

            var loca = window.location.pathname;
            var ara = loca.split('/');
            ara = "/" + ara[1]
            if (ara == "/")
                window.location.reload(true)
        }
        this.setState({ nuevaruta: item.enlace })
    }


    render() {
        return (
            <>
                {(this.state.nuevaruta != "") ? (
                    <Redirect to={this.state.nuevaruta} />
                ) : null}
                <Collapse isOpen={this.props.colapsar}  >

                    {(this.state.lista.map((el, index) => (
                        <div key={"menumovil" + index} className='navbar-movil-menuitem-option'>
                            <button onClick={() => this.activaropcion(index, el.activa)}>
                                {(index == 0) ? (<AiFillHome className="navbar-web-menuicons" />) : null}
                                {(index == 1) ? (<RiFileList2Fill className="navbar-web-menuicons" />) : null}
                                {(index == 2) ? (<GoGear className="navbar-web-menuicons" />) : null}
                                {(index == 3) ? (<FaUsersCog className="navbar-web-menuicons" />) : null}
                                {(index == 4) ? (<BsFillPersonFill className="navbar-web-menuicons" />) : null}



                                {el.titulo}
                            </button>
                            <Collapse isOpen={el.activa} className="navbar-movil-menuitem-option-submenu ">
                                {el.items.map((item, index2) => (
                                    <> {
                                        /*<a href="" className={(this.props.ruta.toString() == item.enlace) ? ("rutaactiva href") : "href"}>
                                       
                                </a>*/
                                        <p key={"menumovilsub" + index2} className={(this.props.ruta.toString() == item.enlace && item.enlace != "/") ? ("rutaactiva href") : "href"} onClick={() => this.cambiarRuta(item)}>{item.nombre} </p>
                                    }  </>

                                ))}
                            </Collapse>
                        </div>
                    )))}
                </Collapse>
            </>
        );
    }
}
