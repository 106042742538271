export function InitData() {

    if (!localStorage.getItem("datos")) {
        localStorage.setItem("datos", "");
    }
}

export function LogInCall(arraydatos) {
    localStorage.setItem("datos", arraydatos); //se guardar los datos del login, position 0 = apikey, position 1 name user, position 2 user
}

export function DelateLogin() {
    localStorage.setItem("datos", "");
}