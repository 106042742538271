import React from "react";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import "./ModalDetalles.css";
import { ChromePicker } from 'react-color';
import { datosDetalles, restablecePass, saveCategoria } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { GiCheckMark } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

export default class ModalDetalles extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            show: this.props.show,
            pagadas: 0,
            nopagadas: 0,
            datosUser: props.datosUser,
            array: [],
            loanding: false,

            sortBy: "email",
            sortOrder: 'asc',
        }

    }

    async componentDidMount() {

        this.setState({ loanding: true })


        var resp = await datosDetalles(this.state.datosUser.idcliente)
        if (resp == 0) {

        }
        else {



            var arrayt = resp.data;
            let pagadas = 0, nopagadas = 0;
            for (let i = 0; i < arrayt.length; i++) {
                arrayt[i].seleccionar = false;
                if (arrayt[i].pagado === "0") {
                    pagadas++;
                }
                else {
                    nopagadas++;
                }
            }

            this.setState({ array: arrayt, pagadas: pagadas, nopagadas: nopagadas });
        }
        this.setState({ loanding: false })

    }

    closemodal = (response) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera);
    }

    formatearFecha = (fecha) => {
        const date = new Date(fecha);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const year = date.getFullYear();
        return `${dia}/${mes}/${year}`;
    }

    seleccionarTabla = (idcuentas) => {
        const { array } = this.state;
        const newData = [];
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (item.idcuentas === idcuentas) {
                newData.push({ ...item, seleccionar: !item.seleccionar });
            } else {
                newData.push({ ...item, seleccionar: false });
            }
        }
        this.setState({ array: newData });
    }


    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    restablecer = async () => {
        this.setState({ loanding: true })
        var resp = await restablecePass(this.state.datosUser.email);
        this.closemodal(0)


    }


    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return (
            <>

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}
                    className="modal-messages">
                    <ModalBody className="bodybackgroundblack">
                        <div className="modalmessage-header" style={{ textAlign: "center", color: "black" }}>
                            <p><b> Detalles del cliente </b></p>
                        </div>
                        <br />




                        <div className="row">

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Nombre: {this.state.datosUser.nombre}</b></p>
                            </div>

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Tipo: {this.state.datosUser.tipocliente}</b></p>
                            </div>

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Feccha registro: {this.state.datosUser.registro}</b></p>
                            </div>

                        </div>


                        <div className="row">

                            <div className="col-4">
                                <p><b>Total cuentas: {this.state.array.length} </b></p>
                            </div>

                            <div className="col-4">
                                <p><b>Pagadas: {this.state.datosUser.pagadas} </b></p>
                            </div>

                            <div className="col-4">
                                <p><b>No pagadas: {this.state.datosUser.nopagadas} </b></p>
                            </div>

                        </div>

                        <br />
                        <br />


                        <div className="row" style={{ backgroundColor: "#51a3ed", color: "black" }}>


                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('fechainicio')}>
                                <label className="txttitlestable">Fecha</label>
                            </div>
                            <div className="col-3  sinpadding" onClick={() => this.ordenarLista('titulo')}>
                                <label className="txttitlestable">Cuenta</label>
                            </div>
                            <div className="col-3  sinpadding" onClick={() => this.ordenarLista('namesubcat')}>
                                <label className="txttitlestable">Categoría</label>
                            </div>
                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('tipo')}>
                                <label className="txttitlestable">Tipo</label>
                            </div>
                            <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('pagado')}>
                                <label className="txttitlestable">Pagado</label>
                            </div>


                        </div>
                        <div className='divscrollhome' style={{ maxHeight: "300px" }} >

                            {this.state.array != [] ?
                                <>
                                    {sortedData.map((item, index) => (

                                        <div className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                            onClick={this.seleccionarTabla.bind(this, item.idcuentas)}>

                                            <div className="col-2 textocentro ">
                                                <label className="subtexttableRecordatorio">{this.formatearFecha(item.fechainicio)}</label>
                                            </div>

                                            <div className="col-3  sinpadding">
                                                <label className="subtexttableRecordatorio">{item.titulo}</label>
                                            </div>

                                            <div className="col-3  sinpadding">
                                                <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                            </div>

                                            <div className="col-2 textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">{item.tipo}</label>
                                            </div>

                                            <div className="col-1 textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    {item.pagado == "0" ?
                                                        <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                        : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                </label>
                                            </div>

                                        </div>
                                    ))}
                                </>
                                : null
                            }

                        </div>
                        <br />
                        <div className="modalmessage-footer">

                            <button className="buttonbase message-alert-ok" onClick={() => this.closemodal(0)}>Cerrar</button>


                            <button className="buttonshadow message-alert-cancel" style={{ width: "190px" }} onClick={() => this.restablecer(0)}>Restablecer contraseña</button>




                        </div>

                    </ModalBody>

                </Modal>
            </>
        )

    }
}