import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { saveCategoria } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import "./ModalMoverCategorias.css"

export default class ModalMoverCategorias extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {

            loanding: false,
            show: this.props.show,
            botones: this.props.options,
            subTotal: 0,
            seleccionarCategoria: "",
            selecCategoria: this.props.arrayInfo,
            arrayCategorias: this.props.arrayCategorias,
            arregloFiltrado: []

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        this.setState({ loanding: true })
        let vidcategoria = this.state.selecCategoria.idcategoria; // El valor de parent que quieres buscar
        this.setState({ seleccionarCategoria: vidcategoria })

        var filtropuesto = this.state.arrayCategorias;
        const datosFiltrados = filtropuesto.filter(item => item.parent === vidcategoria);
        datosFiltrados.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        this.setState({ arregloFiltrado: datosFiltrados });
        this.setState({loanding: false})


    }

    handleChange(event) {
        this.setState({ seleccionarCategoria: event.target.value });
    }

    closemodal = (response, item) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, item);
    }


    cambiarCategorias = async () => {
        this.setState({loanding: !this.stateloanding})
        let arregloFiltrado = this.state.arregloFiltrado;

        let i = 0;
        while (i < arregloFiltrado.length) {
            let json = {
                "id": arregloFiltrado[i].id,
                "idcategoria": arregloFiltrado[i].idcategoria,
                "name": arregloFiltrado[i].name,
                "description": arregloFiltrado[i].description,
                "icon": arregloFiltrado[i].icon,
                "type": arregloFiltrado[i].type,
                "color": arregloFiltrado[i].color,
                "parent": this.state.seleccionarCategoria
            }
            console.log("Update Sub \n" + JSON.stringify(json))

            var respp = await saveCategoria(json)
            i++;
        }

        this.closemodal(1,this.state.seleccionarCategoria);


    }


    render() {
        return (
            <>

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="sm"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}
                    className="modal-messages" >
                    <ModalBody className="bodybackgroundblack">
                        <div className="modalmessage-header" style={{ textAlign: "start" }}>
                            <p><b> Mover Subcategorías </b></p>
                        </div>
                        <br />
                        <div className="modalmessage-body" style={{ textAlign: "center", color: "black", margin: "auto" }}>
                            <p><b>Mover {this.state.arregloFiltrado.length} Sub categorías a:</b> </p>
                            <br />



                            <select value={this.state.seleccionarCategoria} onChange={this.handleChange}
                                style={{ width: "100%", padding: "5px", border: "solid 2px #0066c0", borderRadius: "10px", color: "black", display: "block", margin: "auto" }}>
                             
                                {this.state.arrayCategorias.map((categoria, index) => (
                                    categoria.parent === "" ? (
                                        <option key={index} value={categoria.idcategoria} style={{ backgroundColor: this.state.seleccionarCategoria === categoria.idcategoria ? 'lightblue' : 'white' }}>
                                            {categoria.name}
                                        </option>
                                    )
                                        : null

                                ))}
                            </select>







                        </div>
                        <br />
                        <div className="modalmessage-footer">
                            <button className="buttonshadow message-alert-cancel " onClick={() => this.closemodal(0)}>Cancelar</button>


                            <button className="buttonbase message-alert-ok" onClick={() => this.cambiarCategorias(1)}>Continuar</button>
                        </div>
                    </ModalBody>
                </Modal>
            </>

        )
    }
}

