import React from 'react';


import stylesMenu from './styles.module.scss';


import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { BsList, BsListUl, BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Collapse } from 'reactstrap';
import { ImNewspaper } from 'react-icons/im';
import { SiSap } from 'react-icons/si';
import { IoIosApps } from 'react-icons/io';
import { VscLayersActive } from "react-icons/vsc";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class Sidebar extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      openmenu: true,
      opensubmenu: false,
      pages: [
        {
          to: '/panel',
          text: 'Menú',
          colla: true,
          svg: (
            <BsListUl className={stylesMenu.itemmenu} />
          ),
          submenu: [
            { id: 0, "titulo": 'Usuarios', seleccionar: false },
            { id: 1, "titulo": 'Categorías', seleccionar: false },
            { id: 2, "titulo": 'Clientes', seleccionar: false }
          ]
        },



      ]
    }

  }


  versubmenu = (pos) => {

    //console.log(pos)
    let array_temp = this.state.pages;
    array_temp[pos].colla = !array_temp[pos].colla;

    //console.log(JSON.stringify(array_temp))
    this.setState({ pages: array_temp });

  }


  cliksubmenu = (pos1, pos2) => {

    console.log(pos1 + " " + pos2)

    let array_prin = this.state.pages;
    if (array_prin[pos1].to == "editoratlantisweb") {

      for (let i = 0; i < array_prin[pos1].submenu.length; i++) {
        array_prin[pos1].submenu[i].seleccionar = false;
      }
      array_prin[pos1].submenu[pos2].seleccionar = !array_prin[pos1].submenu[pos2].seleccionar;
      //console.log()
      this.props.selectMenu(array_prin[pos1].submenu[pos2].titulo, "editor");

    }

    else if (array_prin[pos1].to == "editoratlantisweb") {
      this.props.selectMenu(array_prin[pos1].submenu[pos2].titulo, "editor");
    }


    else {

      for (let j = 0; j < array_prin.length; j++) {
        let array_temp = array_prin[j].submenu;

        for (let i = 0; i < array_temp.length; i++) {
          array_temp[i].seleccionar = false;
        }
      }

      array_prin[pos1].submenu[pos2].seleccionar = true;
      this.setState({ pages: array_prin })

      if (pos2 == 0 && pos1 == 0) {
        this.props.selectMenu("home");
      }
      else if (pos2 == 1 && pos1 == 0) {
        this.props.selectMenu("categorias");
      }
      else if (pos2 == 2 && pos1 == 0) {
        this.props.selectMenu("clientes");
      }
      

    }

  }



  render() {

    return (

      <div className={this.state.openmenu ? stylesMenu.sidebarOpen : stylesMenu.sidebar}>


        <div className={stylesMenu.nabvarcssmenu} style={{marginBottom:"124px"}}>

          {this.state.openmenu ?
            <div className={stylesMenu.nabvarcssmenu} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              <BsArrowLeftShort className={stylesMenu.hamburger} onClick={() => this.setState({ openmenu: !this.state.openmenu })} />
              <p className={stylesMenu.lbltittled} onClick={() => this.setState({ openmenu: !this.state.openmenu })}  >Koin</p>
            </div>
            :
            <div className={stylesMenu.nabvarcssmenu} style={{ display: 'block', alignItems: 'center', justifyContent: 'center', }}>
              <p className={stylesMenu.lbltittled} style={{ color: "black", fontWeight: "bold" }} onClick={() => this.setState({ openmenu: !this.state.openmenu })} >Koin</p>

              <BsArrowRightShort className={stylesMenu.hamburger} onClick={() => this.setState({ openmenu: !this.state.openmenu })} />
            </div>
          }


        </div>


        {/*<img className={stylesMenu.imge} src={'/coin.png'} />*/}


        <div className={stylesMenu.linksContainer}>
          {this.state.pages &&
            this.state.pages.map((item, index) => (
              <>

                <a className={this.state.openmenu ? stylesMenu.linkOpen : stylesMenu.normal} onClick={this.versubmenu.bind(this, index)}>
                  <div>
                    {item.svg}
                  </div>
                  {this.state.openmenu ? <p className={stylesMenu.lblstile}>{item.text}</p> : null}

                  {this.state.openmenu ?
                    item.colla ? <RiArrowUpSLine className='icoslider' /> : <RiArrowDownSLine className='icoslider' />
                    : null}
                </a>

                <Collapse isOpen={item.colla} >
                  {(this.state.openmenu) ? (
                    <>{
                      item.submenu.map((subitem, index2) => (
                        <a className={subitem.seleccionar ? stylesMenu.linkOpen_new + " " + stylesMenu.linkOpen_new_select : stylesMenu.linkOpen_new} onClick={this.cliksubmenu.bind(this, index, index2)}>
                          <p className={stylesMenu.lblstile_new}>{subitem.titulo}</p>
                        </a>
                      ))
                    }

                    </>
                  ) : null}

                </Collapse>

              </>
            ))}
        </div>
      </div>

    );
  }
};

