import React from "react";
import { Modal, ModalBody, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { FaUserTie } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import "./AgregarUsuarios.css"
import { AiOutlineClose } from "react-icons/ai";
import { agregaUser } from "../../../servicios/api";

export default class AgregarUsuarios extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            loanding: false,
            show: this.props.show,
            titulo_table: props.bandera == 0 ? "Agregar Cupón" : "Actualizar Cupón",
            modalinfo: props.modalinfo,
            idestatus: -1,
            txtuser: "",
            txtcorreo: "",
            txtapellido: "",
            txtdes: ""
        }
    }

    async componentDidMount() {


        if (this.props.bandera == 1) {
            this.seleccionarestatus(this.state.modalinfo.idestatus);
            this.setState({ txtcupon: this.state.modalinfo.cupon });
            this.setState({ txtdes: this.state.modalinfo.descripcion });
        }

    }

    closemodal = (dato) => {
        this.props.cerrarmodal(dato);
        this.setState({ show: false });
    }

    
    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    seleccionarestatus = (idestatus) => {
        this.setState({ idestatus: idestatus })
    }

    seleccionarestatus = (idestatus) => {
        this.setState({ idestatus: idestatus })
    }






    guardatos = async () => {

        
        if (this.state.txtuser.trim().length > 0) {

            if (this.validateEmail(this.state.txtcorreo)) {


                await this.setState({ loanding: true })

                let json = {
                    "nombre": this.state.txtuser.trim(),
                    "apellido": this.state.txtapellido.trim(),
                    "email": this.state.txtcorreo.trim()
                }

                console.log(JSON.stringify(json))


                var resp = await agregaUser(json)
                if(resp.code == 0){
                    this.closemodal(1)
                }
                else{
                    this.setState({ loanding: false })
                    toast.error(resp.message, { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                    return;
                }
              

            } else {
                toast.warn("Correo inválido", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                return;
            }


        } else {
            toast.warn("Escribe un nombre", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }

       


    }


    validateEmail = (email) => {
        // Expresión regular para validar el correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }





    handlePasswordChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }


    render() {
        return (

            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    style={{ width: "350px" }}
                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="divperfilediv">


                            <div className="row" >
                                <div className="col-auto ">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>Agregar usuario</label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl" ><AiOutlineClose onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>


                            <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <br />

                                <div className="profile-details" style={{ textAlign: 'center', marginTop: "10px" }}>
                                    <div style={{ position: "relative", width: "100%" }}>

                                        <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Nombre" type={"text"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtuser")} value={this.state.txtuser} />


                                    </div>

                                    <br />
                                    <div style={{ position: "relative", width: "100%" }}>

                                        <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Apellido (Opcional)" type={"text"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtapellido")} value={this.state.txtapellido} />

                                    </div>
                                    <br />

                                    <div style={{ position: "relative", width: "100%" }}>

                                        <MdAlternateEmail style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Correo" type={"email"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtcorreo")} value={this.state.txtcorreo} />


                                    </div>






                                    <Button
                                        onClick={this.guardatos}
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            marginTop: "25px",
                                            textTransform: "capitalize",
                                            borderRadius: "30px",
                                            padding: "3px",
                                            fontSize: "medium",
                                            backgroundColor: "#4137a4",

                                            color: "white"
                                        }}

                                    >
                                        {"Agregar"}
                                    </Button>

                                </div>
                            </div>



                        </div>

                    </ModalBody>


                </Modal>
            </>
        )
    }
}

