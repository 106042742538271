import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import "./ListaCuentas.css"
import { GrFormSearch } from 'react-icons/gr';
import { MdRefresh } from 'react-icons/md';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import { ImKey } from "react-icons/im";
import { FaUserCircle } from "react-icons/fa";
import { RiUserAddFill } from "react-icons/ri";
import AgregarUsuarios from "../../componentes/Modals/AgregarUsuarios/AgregarUsuarios";
import { addpruebas, getCuentas, getUser, srtPassword } from "../../servicios/api";
import { HiOutlineKey } from "react-icons/hi";
import ModalDetalles from "../../componentes/Modals/ModalDetalles/ModalDetalles";


export default class ListaCuentas extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            txtusuario: "",
            txt_buscador: "",
            array: [
                {
                    "usuario": "Arias",
                    "estatus": false,
                    "cuentas": 10,
                    "activo": true,
                },
            ],
            array_filtro: [],
            respaldo_data: [],
            loanding: false,
            fechaselect: null,
            fechajson: '',
            ver_multipagos: false,
            ver_fecha: false,
            veruser: false,
            fecha: '',
            sortBy: "email",
            sortOrder: 'asc',
            selectedRowIndex: null,
            selectedRow: null,
            dataUser: [],
            vermodaldetalles: false
        }

    }

    async componentDidMount() {

        this.setState({ loanding: true });
        var resp = await getCuentas()


        if (resp.code == 0) {
            var temp = resp.data;
            for (let i = 0; i < temp.length; i++) {
                temp[i].seleccionar = false;
            }


            this.setState({ array: resp.data })
        }

        setTimeout(async () => {

            this.setState({ loanding: false });

        }, 300);



    }

    handleAgeChange = (index) => {
        this.setState((prevState) => {
            const newData = [...prevState.data];
            // Incrementa la edad del elemento en la posición del índice
            newData[index].edad++;
            return { data: newData };
        });
    };

    save_input_locacion = async (e) => {

        var filtropuesto = this.state.array;
        for (let i = 0; i < filtropuesto.length; i++) {
            filtropuesto[i].seleccionar = false;
        }
        this.setState({ array: filtropuesto })


        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });

        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.email.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }




    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }



    agregarUser = async (index) => {
        console.log("Click " + index)
        this.setState({ veruser: true })
        /*
                let json = {
                    "udid": "123456789012345678901234",
                    "nombre": "Name 1",
                    "apellido": ""
                }
                var resp = await addpruebas(json)
                */


    }

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }



    seleccionarTabla2 = (index, bandera) => {
        console.log(bandera + "  " + index)
        this.setState((prevState) => {
            const newData = prevState.array.map((item, i) => {
                if (i === index) {
                    return { ...item, seleccionado: true };
                } else {
                    return { ...item, seleccionado: false };
                }
            });
            return { array: newData, selectedRow: index };
        });
    };

    seleccionarTabla = (idcliente, bandera) => {
        console.log(bandera + "  " + idcliente)
        if (bandera === "lista") {
            const { array } = this.state;
            const newData = array.map((item) => {
                if (item.idcliente == idcliente) {
                    return { ...item, seleccionar: !item.seleccionar };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array: newData });
        }

        else { // filtro
            const { array_filtro } = this.state;
            const newData = array_filtro.map((item) => {
                if (item.idcliente == idcliente) {
                    return { ...item, seleccionar: !item.seleccionar };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_filtro: newData });

        }
    }

    modalCategorias = (bandera, item) => {

        console.log("ban "+bandera)
        if (bandera == 0) {
            this.setState({ vermodaldetalles: !this.state.vermodaldetalles })
        }
        else {
            this.setState({ dataUser: item })
            this.setState({ vermodaldetalles: true })
        }


    }


    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return (
            <>


                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.veruser == true) ? (
                    <AgregarUsuarios show={this.state.veruser} infoModal={[]}
                        cerrarmodal={this.closemodal} />
                ) : null}

                {(this.state.vermodaldetalles == true) ? (
                    <ModalDetalles show={this.state.vermodaldetalles} datosUser={this.state.dataUser} cerrarmodal={this.modalCategorias} />
                ) : null}



                <div className="contenedorhome  ">

                    <div className="row scroll">
                        <div className="col">

                            <br />
                            <br />
                            <label className="lbllistat letra">Clientes</label>

                            <br />



                            <br />
                            <div className="row align-items-end">
                                <div className="col-auto ">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                        </div>
                                        <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                            className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                    </div>
                                </div>


                                <div className="col"></div>

                                <div className="col-auto">


                                </div>

                            </div>


                            <div className="shr "></div>
                            <br />


                            <div className="divhorizontal ">

                                <div className="row srows">


                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('email')}>
                                        <label className="txttitlestable">Correo</label>
                                    </div>
                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('nombre')}>
                                        <label className="txttitlestable">Usuario</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('cuentas')}>
                                        <label className="txttitlestable">Cuentas</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('pagadas')}>
                                        <label className="txttitlestable">Pagadas</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('nopagadas')}>
                                        <label className="txttitlestable">No pagadas</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('tipocliente')}>
                                        <label className="txttitlestable">Tipo</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('estatus')}>
                                        <label className="txttitlestable">Estatus</label>
                                    </div>
                                    {/*<div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('registro')}>
                                        <label className="txttitlestable">Fecha de registro</label>
                                    </div>*/}


                                </div>
                                <div className='divscrollhome'>



                                    {this.state.txt_buscador.length == 0 ?
                                        <>

                                            {sortedData.map((item, index) => (

                                                <>

                                                    <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                        onClick={this.seleccionarTabla.bind(this, item.idcliente, "lista")} >


                                                        <div className="col-2 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">
                                                                {item.email && item.email.length > 0 ?
                                                                    item.email[0] + '*****' + item.email.substring(item.email.indexOf('@') - 1) :
                                                                    item.email}
                                                            </label>
                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.nombre}</label>
                                                        </div>
                                                        <div className="col-1 spaciseleccionarTablaostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.cuentas}</b></label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.pagadas}</b></label>

                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.nopagadas}</b></label>

                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.tipocliente}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.estatus}</label>

                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <Button className="buttonCats"  onClick={this.modalCategorias.bind(this, 1, item)}>Ver</Button>
                                                        </div>


                                                    </div>


                                                </>

                                            ))}

                                        </> :
                                        <>

                                            {this.state.array_filtro.map((item, index) => (

                                                <>

                                                    <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                        onClick={this.seleccionarTabla.bind(this, item.idcliente, "filtro")} >
                                                        <div className="col-2 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">
                                                                {item.email && item.email.length > 0 ?
                                                                    item.email[0] + '*****' + item.email.substring(item.email.indexOf('@') - 1) :
                                                                    item.email}
                                                            </label>

                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.nombre}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.cuentas}</b></label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.pagadas}</b></label>

                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable"><b>{item.nopagadas}</b></label>

                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.tipocliente}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.estatus}</label>

                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.registro}</label>

                                                        </div>


                                                    </div>

                                                </>

                                            ))}


                                        </>}

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </>
        )
    }
}