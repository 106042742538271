import React from "react";
import { Modal, ModalBody, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

import "./ChangePassword.css"
import { changePassword } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { BsShieldLockFill } from "react-icons/bs";



export default class ChangePassword extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            show: this.props.show,
            loanding: false,
            txtsalir: false,
            txtpassactual: "",
            txtpas1: "",
            txtpas2: ""

        }

    }

    componentDidMount() {

        var datos = localStorage.getItem("datos");
        let array = datos.split(",");
        this.setState({ txtusuario: array[1] });

    }

    closemodal = (ban) => {
        this.props.cerrarmodal(ban);
        this.setState({ show: false });
    }

    guardatos = async () => {


        if (this.state.txtpassactual.trim() === '') {
            toast.error("Datos incorrectos", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }

        if (this.state.txtpas1.trim() !== '' && this.state.txtpas2.trim() !== '') {
            if (this.state.txtpas1 === this.state.txtpas2) {
                // Las variables no están vacías y tienen el mismo texto

                this.setState({ loanding: !this.state.loanding })

                let json = {
                    "password": this.state.txtpassactual.trim(),
                    "nuevopassword": this.state.txtpas1.trim(),
                    "nuevopassword": this.state.txtpas2.trim(),
                }

                var resp = await changePassword(json)
                if (resp.code == 0) {

                    toast.success("Informacón actualizada", { autoClose: 2500, position: toast.POSITION.TOP_LEFT });
                    this.closemodal(1)

                }
                else {
                    this.setState({ loanding: false })
                    toast.error(resp.message, { autoClose: 3000, position: toast.POSITION.TOP_LEFT });
                    return;
                }



            } else {
                // Las variables tienen texto diferente, lanza un error
                toast.error("Las contraseñas no coinsiden", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        } else {
            // Al menos una de las variables está vacía, lanza un error
            toast.error("Las contraseñas no coinsiden", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }



    }


    closemodal = (dato) => {
        this.props.cerrarmodal(dato);
        this.setState({ show: false });
    }

    handlePasswordChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }


    render() {
        return (

            <>
                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    style={{ width: "350px" }}
                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="divperfilediv">


                            <div className="row" >
                                <div className="col-auto ">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>Cambio de Contraseña</label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl" ><AiOutlineClose onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>


                            <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <br />

                                <div className="profile-details" style={{ textAlign: 'center', marginTop: "10px" }}>

                                    <div style={{ position: "relative", width: "100%" }}>

                                        <BsShieldLockFill  style={{ color: "green", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Contraseña Actual" type={"password"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtpassactual")} value={this.state.txtpassactual} />
                                    </div>
                                    <br />

                                    <div style={{ position: "relative", width: "100%" }}>

                                        <BsShieldLockFill  style={{ color: "green", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Nueva Contraseña" type={"password"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtpas1")} value={this.state.txtpas1} />
                                    </div>
                                    <br />

                                    <div style={{ position: "relative", width: "100%" }}>

                                        <BsShieldLockFill  style={{ color: "green", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Nueva Contraseña" type={"password"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtpas2")} value={this.state.txtpas2} />
                                    </div>
                                    <br />

                                    <Button onClick={this.guardatos} variant="contained" fullWidth
                                        style={{
                                            marginTop: "25px", textTransform: "capitalize", borderRadius: "30px", padding: "3px",
                                            fontSize: "medium", backgroundColor: "#4137a4", color: "white"
                                        }}>
                                        {"Cambiar Contraseña"}
                                    </Button>


                                </div>
                            </div>



                        </div>

                    </ModalBody>


                </Modal>
            </>
        )
    }
}