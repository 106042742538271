import React from "react";

import Dashboard from "../../componentes/Dashboard/Dashboard";
import Sidebar from "../../componentes/SideBar/sidebar";

//import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";


export default class Panel extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            ventana: 'home',
            continuar: false,
            salir: false,
            editor: false,
        }

    }

    componentDidMount() {

        var datos = localStorage.getItem("datos");
        if (datos == null) {
            this.setState({ salir: true })
        }
        else {
            this.setState({ continuar: true })
        }

    }

    selectMenu = (ventana, bandera) => {
        if (bandera == "editor") {
            this.setState({ editor: true })
        }
        else {
            this.setState({ ventana: ventana })
        }
    }

    render() {
        return (

            <>

   
              
                    <div className='row rowdaaa ' style={{ float: 'left' }}>
                        <div className="col-auto">
                            <Sidebar selectMenu={this.selectMenu} />
                        </div>
                        <div className="col scrollss">
                            <div className=" scrolldf">
                                <Dashboard ventana={this.state.ventana} />
                            </div>
                        </div>
                    </div> 
           
            </>

        )

    }

}