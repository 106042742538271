import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login/Login';
import Panel from './pages/Panel/Panel';
import Perfil from './pages/Perfil/Perfil';



export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Switch>
      
        {/*<Route path="/detallesordencosecha" component={DetallesOrdenCosecha} />*/}
         <Route path="/home" component={Panel} />
         <Route path="/perfil" component={Perfil} />
        
        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );

}

export default App;